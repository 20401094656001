import {reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance,nextTick} from 'vue';
import XbfsListUtil ,{IXbfsListDataObj} from './xbfsListUtil';

export default defineComponent ({
    name: 'XbfsList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IXbfsListDataObj=reactive<IXbfsListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                gridTitle:'信保复审',
                queryParam: {},
                cardFrom:'XbfsList',
                isShowFixCol:false,
                modelMethod: utils.OrderProviderApi.buildUrl('/xbfs/pageData')
            },
            otherParams:{
                dialogTitle:'',
                type:0,
                count:0,
                dialogVisible:false,
                selInfo:{
                    F_PI_ID:'',//piId
                    F_ORDER_CODE:'',//订单号
                    F_AMOUNT:0,//金额
                },
                customerData:[],//客户下拉数据
            },
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new XbfsListUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'customerId'==params.comboId){
                    return dataObj.otherParams.customerData;
                }
            }
        })

        const formatPageInfo=(options:any)=>{
            return options;
        }
        const gridLoaded=(res:any)=>{
            dataObj.otherParams.count=res.total;
        }
        return{
            ...toRefs(dataObj),comboSelect,formatPageInfo,gridLoaded
        }
    }
});